<template>
  <div class="automatic-case-creation">
    <v-row>
      <v-col sm="6">
        <span class="headline">{{ pageName }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="6">
        <v-switch
          hide-details
          v-model="acc"
          :label="`Service: ${acc ? 'Enabled' : 'Disabled'}`"
          class="float-right mt-0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-card elevation="2">
          <v-card-text class="text-center">
            <p>Some Total</p>
            <h1>12</h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="3">
        <v-card elevation="2">
          <v-card-text class="text-center">
            <p>Some Total</p>
            <h1>12</h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="3">
        <v-card elevation="2">
          <v-card-text class="text-center">
            <p>Some Total</p>
            <h1>12</h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="3">
        <v-card elevation="2">
          <v-card-text class="text-center">
            <p>Some Total</p>
            <h1>12</h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="8">
        <v-btn color="info" class="mr-3" small>
          All Cases
        </v-btn>
        <v-btn class="mr-3" color="success" small>
          Pending Approval Cases
        </v-btn>
        <v-btn color="warning" small>
          Quarantined Cases
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="4" class="text-right">
        <v-btn color="success" class="mr-3" small>
          Approve Selected
        </v-btn>
        <v-btn color="error" small>
          Delete Selected
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="cases"
          :single-select="singleSelect"
          show-select
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.procedureType`]="{ item }">
            <v-chip v-if="item.procedureType == 'TKA'" color="purple" small text-color="white">{{
              item.procedureType
            }}</v-chip>
            <v-chip v-if="item.procedureType == 'THA'" color="teal" small text-color="white">{{
              item.procedureType
            }}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="success" class="mr-3" @click="onEditSurgeon(item)">mdi-clipboard-check</v-icon>
            <v-icon color="warning" class="mr-3" @click="onDeleteSurgeon(item)">mdi-alert</v-icon>
            <v-icon color="error" @click="onDeleteSurgeon(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    pageName: 'Automatic Case Creation',
    acc: true,
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Patient Name', value: 'patientName' },
      { text: 'Procedure Type', value: 'procedureType' },
      { text: 'MPS Name', value: 'MPS' },
      { text: 'Primary Exam Id', value: 'primaryExamId' },
      { text: 'Share Date', value: 'shareDate' },
      { text: 'Need By Date', value: 'neddByDate' },
      { text: 'Actions', value: 'actions' },
    ],
    cases: [
      {
        patientName: 'Doe, John',
        procedureType: 'TKA',
        MPS: 'Pandis, Antonios',
        primaryExamId: 'C-123456-1',
        shareDate: '06/28/2022',
        neddByDate: '06/28/2022',
      },
      {
        patientName: 'Doe, Jane',
        procedureType: 'THA',
        MPS: 'Chandra, Sahil',
        primaryExamId: 'C-654321-1',
        shareDate: '06/28/2022',
        neddByDate: '06/28/2022',
      },
      {
        patientName: 'Doe, John',
        procedureType: 'TKA',
        MPS: 'Pandis, Antonios',
        primaryExamId: 'C-123456-1',
        shareDate: '06/28/2022',
        neddByDate: '06/28/2022',
      },
      {
        patientName: 'Doe, John',
        procedureType: 'TKA',
        MPS: 'Pandis, Antonios',
        primaryExamId: 'C-123456-1',
        shareDate: '06/28/2022',
        neddByDate: '06/28/2022',
      },
      {
        patientName: 'Doe, John',
        procedureType: 'TKA',
        MPS: 'Pandis, Antonios',
        primaryExamId: 'C-123456-1',
        shareDate: '06/28/2022',
        neddByDate: '06/28/2022',
      },
    ],
  }),
  computed: {},
  methods: {},
  async created() {},

  watch: {},
};
</script>

<style></style>
